function LightBox(lightbox) {
  this.elem = $(lightbox);
  this.elem.on('click','[data-action]',this._action.bind(this));
  this.currImage = -1;
  var images = this.elem.find('[data-lightbox]');
  this.images = [];
 
  for( var i=0; i<images.length ; i++ ) {
    this.images.push({
      thumb : images.eq(i),
      preview : images.eq(i).find('img').data()
    });
    
    if( i > 5 ) {
      images.eq(i).closest('li').hide();
    }
  }

  this.thumbnails = new Thumbnails(this.elem.find('.gallery-row'), this);
  
  this.gotoImage(0);

  this.status = 'small';
  this.clickCatcher = $('<div>');
  this.wrapperElement = $('<div>');
  this.largeImage = $('<img>');
  this.animationData = {};
  this.controller = '<div>' +
                      '<span class="fa fa-arrow-circle-o-left fa-2x" data-action="prev-image"></span> ' +
                      '<span class="fa fa-arrow-circle-o-right fa-2x" data-action="next-image"></span> ' +
                      '<span class="fa fa-times-circle-o fa-2x" data-action="close"></span> ' +
                      // '<span class="fa fa-expand fa-2x"></span>' +
                      '</div>';
  this.controller = $(this.controller);
  this.controller.hide();

  this.controller.on('click','[data-action]',this._action.bind(this));
  
  this.controller.css({
    zIndex: 100,
    color: 'white',
    position: 'fixed',
    left: 0,
    top: 0
  });
  
  
  this.wrapperElement.addClass('wrapper-image').css({
    position: 'fixed',
    overflow: 'hidden',
    lineHeight: 0,
    backgroundColor: 'rgba(0,0,0,0.8)',
    zIndex: 51
  });

  this.largeImage.css({
    position: 'absolute',
    left: 0,
    top: 0
  });

  this.wrapperElement.append(this.largeImage);

  this.clickCatcher.css({
    position: 'fixed',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    zIndex: 50
  });
  
  this.windowResized();
  
  lightbox.on('mouseenter',this._focus.bind(this));
  lightbox.on('mouseleave',this._blur.bind(this));
  
  this.keyDown = this.keyDown.bind(this);
  
  $(window).on('resize', this.windowResized.bind(this));
  
 /* this.on('change',function(e) {
    debug(e);
  });
  */
}

LightBox.prototype = new EventEmitter();

LightBox.prototype._showControlls = function() {
  this.controller.fadeIn(500);
};

LightBox.prototype._setupElement = function( idx ) {
  
  var element = this.images[idx];
  if( !element.galleryImage ) {
    element.previewElement = $('<img>', {
      src: element.thumb.attr('href').replace('.jpg', '@small.jpg')
    }).data(element.preview);

    element.galleryImage = new GalleryImage(element.previewElement, this);
    element.previewContainer = $('<div>', {
      class: 'image'
    });  
  
    element.previewContainer.append(element.previewElement);
    element.previewContainer.css({
      position: 'absolute',
      left : (Math.max(900,this.windowWidth)*idx)+'px'
    });
  }
};

LightBox.prototype._action = function(e) {
  e.preventDefault();
  if( $(e.target).data().action === 'next-image' ) {
    this.nextImage();
  } else if( $(e.target).data().action === 'prev-image' ) {
    this.prevImage();
  } else if( $(e.target).data().action === 'close' ) {
    this.shrink();
  }
};

LightBox.prototype._focus = function() {
  $(window).on('keydown', this.keyDown);
};

LightBox.prototype._blur = function() {
  $(window).off('keydown', this.keyDown);
};


LightBox.prototype.keyDown = function(e) {
  
  if( e.which === 39 ) {
    this.nextImage();
  } else if( e.which === 37 ) {
    this.prevImage();
  } else if( e.which === 27 ) {
    this.shrink();
  }
};

LightBox.prototype.gotoImage = function(idx) {
  
  
  var prevImage = this.currImage;
  
  
  idx = idx%this.images.length;
  if( idx < 0 ) {
    idx = this.images.length+idx;
  }
  
  this.emit('change', {
    index: idx,
    prevIndex: prevImage
  });

  
  this._setupElement(idx);
  this.currImage = idx;
  //this.elem.find('.gallery-preview .image').remove();
  this.elem.find('.gallery-preview .images').append(this.images[idx].previewContainer);
  this.images[idx].galleryImage.setupEvents();
  
  
  if( this.status === 'large' ) {
    /*
      - wait until larg image is loaded
        - animate the preview panel
        - aniamte the lightbox panel
    */
    
    this.activeImage = this.images[idx].galleryImage;
    
    debug('wait for the image to be loaded!!!!');
    this.images[idx].galleryImage.loadImage()
    .bind(this)
    .then(function() {
      //TODO this is not ok because if chaning th eimage while the other still loaded mit result in a race condition
      //     need to handel that int he same manner as set active ....., propably use the same code, and in the active just differ
      //     betweenenalere and corss fade.
      this.largeImage.attr('src', this.activeImage.large.src);
      this.windowResized();
      this.animatePreviewPanel(idx,prevImage);
    });
  } else {
    if( prevImage !== -1 ) {
      this.animatePreviewPanel(idx,prevImage);
    } 
  }
 
  
  
    
  
  this.queueToOpen(null);
};

LightBox.prototype.animatePreviewPanel = function(idx, prevImage) {
  var diff = idx-prevImage;
  
  

  var bg1 = this.elem.find('.gallery-bg1');
  var bg2 = this.elem.find('.gallery-bg2');
  var bg3 = this.elem.find('.gallery-bg3'); 
  console.dir(bg1.length);
  
  $({
    percent: 0
  }).animate({
    percent: 1
  }, {
    progress: (function(el) {
      var tmpWidth = (Math.max(900,this.windowWidth)*prevImage+diff*Math.max(900,this.windowWidth)*(el.elem.percent));
      this.elem.find('.gallery-preview .images').css({
        left: '-'+(Math.max(900,this.windowWidth)*prevImage+diff*Math.max(900,this.windowWidth)*(el.elem.percent))+'px'
      });
      
      bg3.css({
        backgroundPosition: '-'+(tmpWidth*0.8) + 'px 0'
      });
      bg2.css({
        backgroundPosition: '-'+(tmpWidth*0.3) + 'px 0'
      });
    
      bg1.css({
        backgroundPosition: '-'+(tmpWidth*0.2) + 'px 0'
      });
      
      /*this.images[idx].previewContainer.css({
        position: 'absolute',
        left: (Math.max(900,this.windowWidth)*prevImage+diff*Math.max(900,this.windowWidth)*(1-el.elem.percent))+'px'
      });*/
    }).bind(this),
    duration: 1000
  });
};

LightBox.prototype.nextImage = function() {
  this.gotoImage(this.currImage+1);
};

LightBox.prototype.prevImage = function() {
  this.gotoImage(this.currImage-1);
};

LightBox.prototype.windowResized = function() {
  //TODO here we should correct the aniamtion offset ....
  
  this.windowWidth = $(window).innerWidth();
  this.windowHeight = $(window).innerHeight();
  this.windowRatio = this.windowWidth / this.windowHeight;
 

  if (this.status === 'large') {
    this.wrapperElement
      .width(this.windowWidth)
      .height(this.windowHeight);
      
      
    var destImageWidth;
    var destImageHeight;

    if (this.activeImage.large.ratio < this.windowRatio) {
      destImageWidth = this.windowHeight * this.activeImage.large.ratio;
    } else {
      destImageWidth = this.windowWidth;
    }
  
    destImageHeight = destImageWidth / this.activeImage.large.ratio;

    this.largeImage.css({
      width: destImageWidth,
      height: destImageHeight,
      left: (this.windowWidth - destImageWidth)/2,
      top: (this.windowHeight - destImageHeight)/2
    });
  }
};



LightBox.prototype.queueToOpen = function(img) {
  //if( $('body').get(0).webkitRequestFullscreen ) {
  //  $('body').get(0).webkitRequestFullscreen();
  //} else if( $('body').get(0).mozRequestFullscreen ) {
  //  $('body').get(0).mozRequestFullscreen();
  //} else if( $('body').get(0).requestFullscreen ) {
  //  $('body').get(0).requestFullscreen();
  //}
  
  debug('queueToOpen');

  if (this.queuedImage && this.queuedImage !== img) {
    this.queuedImage.wasDequeued();
  }
  this.queuedImage = img;

};

LightBox.prototype.wrapperClick = function() {
  this.shrink();
};



LightBox.prototype.readyToOpen = function(img) {
  debug('queueToOpen');
  if (this.queuedImage === img) {
    debug('queueToOpen: '+this.status);
    if (this.status === 'small') {
      this.activeImage = img;
      this.enlarge();
    }

    /*
    status === small:
       this.queuedImage = null;
       enlarge that image, status = 'enlargin'
    
    status === large:
       this.queuedImage = null;
       iamge transition, status = 'transition'
    
    status === 'enlargin' || status === 'shrinking' || status === 'transition'
       do nothing
    
    */
  }
};


LightBox.prototype.enlargeShrinkAnimation = function(animation, progress, remainingMs) {
  
  //if( posUpdateRequired ) {
  //  /*
  //    TODO we only should do this while shrinking
  //  */
  //  posUpdateRequired = false;
  //  var prevOffset = wrapperElement.offset();
  //  wrapperElement.offset(this.elm.offset());
  //  this.animationData.startOffset = wrapperElement.position();
  //  wrapperElement.offset(prevOffset);
  //}
  //
  
  
  var animationData = this.animationData;
  var percent = animation.elem.percent;
  var previewInfo = this.activeImage.preview;
  
  var srcImageWidth = previewInfo.scaleWidth;
  var srcImageHeight = previewInfo.scaleWidth / this.activeImage.large.ratio;
  
  
  this.wrapperElement.css({
    left: animationData.startOffset.left * (1 - percent),
    top: animationData.startOffset.top * (1 - percent)
  })
  .width(animationData.dimension.width + (this.windowWidth - animationData.dimension.width) * percent)
  .height(animationData.dimension.height + (this.windowHeight - animationData.dimension.height) * percent);

  var destImageWidth;
  var destImageHeight;

  if (this.activeImage.large.ratio < this.windowRatio) {
    destImageWidth = this.windowHeight * this.activeImage.large.ratio;
  } else {
    destImageWidth = this.windowWidth;
  }
  
  destImageHeight = destImageWidth / this.activeImage.large.ratio;

  this.largeImage.css({
    width: srcImageWidth + (destImageWidth - srcImageWidth) * percent,
    height: srcImageHeight + (destImageHeight - srcImageHeight) * percent,
    left: -previewInfo.offsetX * (1 - percent) + (this.windowWidth - destImageWidth) / 2 * percent,
    top: -previewInfo.offsetY * (1 - percent) + (this.windowHeight - destImageHeight) / 2 * percent
  });
};


LightBox.prototype._enlargeImage = function() {
  this.status = 'enlarging';
};
