LightBox.prototype.shrink = function() {
  if( this.status !== 'large') {
    return;
  }
  this.status = 'shrinking';
  this.controller.fadeOut(500);
  
  
  var animationData = this.animationData;
  
  
  var tmpOffset = this.wrapperElement.offset();
  this.wrapperElement.offset(this.activeImage.elm.offset());
  animationData.startOffset = this.wrapperElement.position();
  this.wrapperElement.offset(tmpOffset);

  $({
    percent: 1
  }).animate({
    percent: 0
  }, {
    progress: this.enlargeShrinkAnimation.bind(this),
    duration: 1000,
    complete: this.shrinkEnd.bind(this)
  });
};

LightBox.prototype.shrinkEnd = function() {
  this.status = 'small';
  $('html').removeClass('no-scroll');
  this.wrapperElement.remove();
  this.clickCatcher.remove();
  this.activeImage.show();
  this.activeImage = null;
};
