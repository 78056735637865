LightBox.prototype.enlarge = function() {
  var $body = $('body');
  $('html').addClass('no-scroll');
  
  $body.append(this.controller);
  $body.append(this.clickCatcher);
  $body.append(this.wrapperElement);
  
  this.wrapperElement.on('click', this.wrapperClick.bind(this) );
  
  this.wrapperElement.offset(this.activeImage.elm.offset());
  this.wrapperElement.outerWidth(this.activeImage.elm.outerWidth());
  this.wrapperElement.outerHeight(this.activeImage.elm.outerHeight());
  this._enlargeImage();
  this.activeImage.hide();
  
  
  this.animationData.dimension = {
    width: this.activeImage.elm.outerWidth(),
    height: this.activeImage.elm.outerHeight()
  };
  
  this.animationData.startOffset = this.wrapperElement.position();

  var previewInfo = this.activeImage.preview;
  this.largeImage.attr('src', this.activeImage.large.src);
  this.largeImage.css({
    width: previewInfo.scaleWidth,
    height: previewInfo.scaleWidth / this.activeImage.large.ratio,
    left: (-previewInfo.offsetX) + 'px',
    top: (-previewInfo.offsetY) + 'px'
  });
  

  var self = this;
  $({
    percent: 0
  }).animate({
    percent: 1
  }, {
    progress: this.enlargeShrinkAnimation.bind(this),
    duration: 1000,
    complete: this.enlargeEnd.bind(this)
  });
};

LightBox.prototype.enlargeEnd = function() {
  this._showControlls();
  this.status = 'large';
  
  //this._resetFadeOutTimer();
  
};

/*LightBox.prototype._fadeOutControls = function() {
  this.controller.fadeOut(500);
};


LightBox.prototype._resetFadeOutTimer = function() {
  clearTimeout(this._fadeOutTimer);
   this.controller.stop().fadeIn(500);
  $(window).one('mousemove',this._resetFadeOutTimer.bind(this));
  this._fadeOutTimer = setTimeout(this._fadeOutControls.bind(this),3000);
};*/
