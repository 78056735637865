require(['jquery','debug','bluebird', 'eventemitter'], function(jQuery, debug, Promise, EventEmitter) {
  
  var dataKey = 'gallery-element';
  var eventKey = 'gallery-element';
  debug.enable("*");
  
  debug('Debug test');
  /*$(document).on('click', '.gallery-image', setUpGallery);

  function setUpGallery(event) {
    if (!$(this).data(dataKey)) {
      $(this).data(dataKey, new GalleryImage($(this)));
      $(this).data(dataKey).click(event);
    }
  }*/


  //var lightBox = new LightBox();

  $(function() {
    $('.lightbox').each(function() {
      if( !$(this).data('LightBox') ) {

        $(this).data('LightBox',new LightBox($(this)));
      }
    });
  });