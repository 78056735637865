function GalleryImage(elm, lightBox) {
  this.elm = elm;

  this.lightBox = lightBox;
  this.smallUrl = this.elm.attr('src');
  this.largeUrl = this.smallUrl.replace(/@small/, '');
  
  this.preview = {};
  this.preview.offsetX = this.elm.data().offsetX;
  this.preview.offsetY = this.elm.data().offsetY;
  this.preview.scaleWidth = this.elm.data().scaleWidth;


  this.click =  this.click.bind(this);
  this.setupEvents();
}

GalleryImage.prototype.setupEvents = function() {

  this.elm.on('click', this.click);
};

GalleryImage.prototype.loadImage = function() {
  var url = this.largeUrl;

  function _loadImage(resolve, reject) {
    var img = new Image();

    img.onerror = reject;
    img.onload = function() {
      resolve({
        element: img,
        src: url
      });
    };
    img.src = url;
  }


  if (!this._imagePromise) {
    this.showLoading();
    this._imagePromise = new Promise(_loadImage);
    this._imagePromise
    .bind(this)
    .then(this._imageLoaded);
  } else {
    if (!this._imagePromise.isFulfilled()) {
      this.showLoading();
    }
  }


  return this._imagePromise;
};

GalleryImage.prototype._imageLoaded = function(img) {
  this.hideLoading();
  this.large = img;
  this.large.width = this.large.element.width;
  this.large.height = this.large.element.height;
  this.large.ratio = this.large.width / this.large.height;
  
  return this.large;
};

GalleryImage.prototype.showLoading = function() {
  this.elm.css({
    'outline': '4px solid red'
  });
};

GalleryImage.prototype.hideLoading = function() {
  this.elm.css({
    'outline': ''
  });
};

GalleryImage.prototype.wasDequeued = function() {
  this.hideLoading();
};

GalleryImage.prototype.hide = function(e) {
  /*this.elm.css({
    visibility: 'hidden'
  });*/
};

GalleryImage.prototype.show = function(e) {
  this.elm.css({
    visibility: ''
  });
};

GalleryImage.prototype.click = function(e) {
  e.preventDefault();
  e.stopPropagation();

  this.lightBox.queueToOpen(this);
  this.loadImage()
  .bind(this)
  .then(function() {
    this.lightBox.readyToOpen(this);
  });
};