var Thumbnails = function(elem, lightbox) {
  this.elem = elem;
  this.lightbox = lightbox;
  this.update = this.update.bind(this);
  this.changeImage = this.changeImage.bind(this);
  this.changePage = this.changePage.bind(this);
  this.pageIndicators = this.elem.find('.page-indicators');
  this.elementsPerPage = 6;
  this.currentPage = 0;
  this.pageCount = Math.ceil(this.lightbox.images.length/this.elementsPerPage);
  
  
  for( var i=0; i<this.pageCount ; i++ ) {
    this.pageIndicators.append('<span class="page-indicator fa fa-circle-o"></span>');
  }
  
  this.lightbox.on('change', this.update);
  this.elem.on('click', 'a', this.changeImage);
  this.elem.on('click', '.page-indicator', this.changePage);
};

Thumbnails.prototype.changeImage = function(e) {
  e.preventDefault();
  var li = $(e.target).closest('li');
  this.lightbox.gotoImage(li.index());
};


Thumbnails.prototype.changePage = function(e) {
  var pageIndicator = $(e.target);
  this.currentPage = pageIndicator.index();
  this.lightbox.gotoImage( pageIndicator.index() *  this.elementsPerPage);
  this.updateThumbnails();
};

Thumbnails.prototype.updateThumbnails = function() {
  debug('update thumbnails');

  this.elem.find('li').hide();
  var startElement = this.currentPage*this.elementsPerPage;
  for( var i=0; i<this.elementsPerPage ; i++ ) {
    if( startElement+i < this.lightbox.images.length ) {
      debug('updateThumbnails %s %s ', startElement, i);
      this.lightbox.images[startElement+i].thumb.closest('li').show();
    }
  }
  
};

Thumbnails.prototype.update = function(e) {
  
  this.elem.find('li').removeClass('active');
  this.elem.find('li').eq(e.index).addClass('active');
  this.elem.find('.page-indicator').removeClass('fa-circle page-indicator-active').addClass('fa-circle-o');
  
  var nextPage = Math.floor(e.index/this.elementsPerPage);
  if( this.currentPage !== nextPage ) {
    this.currentPage = nextPage;
    this.updateThumbnails();
  }
  this.elem.find('.page-indicator').eq(this.currentPage).addClass('fa-circle page-indicator-active');
  
 
};